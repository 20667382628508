<template>
  <v-container class="pa-0">
    <v-row justify="center" :class="{ 'text-center': smAndDown }">
      <h2 id="scrollTarget" class="my-4">{{ activeTranslation?.title }}</h2>
    </v-row>
    <v-row class="d-flex flex-row justify-center px-0 py-2">
      <v-col
        v-for="(wasteCardItem, index) in sortedCards"
        :key="index"
        :class="{
          'd-none': (hideListLongerThan ? index >= hideListLongerThan : false) && !showAllCards,
          'px-2 px-md-3': true,
        }"
        cols="6"
        sm="4"
        md="3"
        xl="2">
        <CdeCard
          v-if="(hideListLongerThan ? index < hideListLongerThan : false) || showAllCards"
          class="fill-height d-flex flex flex-column pa-0 pa-md-2 text-center"
          width="100%">
          <v-card-item class="px-2">
            <v-card-title
              class="card-header cursor-pointer"
              @click="goToDestination(wasteCardItem)">
              {{ wasteCardItem.title }}
            </v-card-title>
          </v-card-item>
          <v-img
            class="cursor-pointer"
            :max-height="smAndUp ? '150px' : '80px'"
            :src="`https://epd.directus.app/assets/${wasteCardItem.image}?format=webp`"
            :srcset="`https://epd.directus.app/assets/${wasteCardItem.image}?format=webp 1x, https://epd.directus.app/assets/${wasteCardItem.image}?format=webp 2x`"
            :alt="wasteCardItem.image_alt_text || ''"
            @click="goToDestination(wasteCardItem)" />
          <v-card-item>
            <v-card-subtitle>{{ wasteCardItem.description }}</v-card-subtitle>
            <v-card-subtitle
              class="font-weight-bold text-decoration-underline cursor-pointer"
              @click="showMoreInfo(wasteCardItem)">
              {{ t('more_info') }}
            </v-card-subtitle>
          </v-card-item>
          <v-card-actions class="justify-center">
            <ButtonSecondary class="mobile-btn" @click="goToDestination(wasteCardItem)">
              {{ wasteCardItem.button_label }}
            </ButtonSecondary>
          </v-card-actions>
        </CdeCard>
      </v-col>
    </v-row>

    <v-row
      v-if="
        hideListLongerThan &&
        cardItem.waste_card_items?.length &&
        cardItem.waste_card_items?.length > hideListLongerThan
      ">
      <v-col class="d-flex justify-center more-cards my-10">
        <ButtonText @click="showAllCardsToggle">
          <v-row justify="center">
            <v-col class="pb-0 text-center font-weight-bold" cols="12">
              <span v-if="!showAllCards">{{ t('show_more_wastetypes') }}</span>
              <span v-if="showAllCards">{{ t('show_less_wastetypes') }}</span>
            </v-col>
            <v-col class="pt-0 text-center" cols="12">
              <v-icon class="mdi-chevron">
                {{ showAllCards ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-col>
          </v-row>
        </ButtonText>
      </v-col>
    </v-row>

    <ComponentSidebar :title="t('allowed_waste')" :persistent="false">
      <SidebarWasteRule
        v-if="currentComponentSidebarType === ACTIVE_SIDEBAR_TYPE.RULES"
        :key="clickedFraction?.id ?? 0"
        :fraction="clickedFraction" />
    </ComponentSidebar>
  </v-container>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n'
import { useDisplay } from 'vuetify'
import { ACTIVE_SIDEBAR_TYPE } from '~/types/enums'
import type { Cards, CardsTranslation, CardWasteCard, Fraction, WasteCard } from '~/types/types'
const { smAndUp, xlAndUp, smAndDown } = useDisplay()

const props = defineProps<{
  cardItem: Cards
}>()

const { cardItem } = toRefs(props)
const sortedCards = ref<WasteCard[] | null>(
  cardItem.value.waste_card_items?.map((item: CardWasteCard) => item.waste_card_id) || [],
)
const showAllCards = ref(false)
const { t, locale, locales } = useI18n()
const router = useRouter()

const fractionStore = useFractionStore()
fractionStore.getFractionData()
const { fractions, fractionsSorted } = storeToRefs(fractionStore)
const offerStore = useOfferStore()
const sidebarStore = useSidebarStore()
const { currentComponentSidebarType } = storeToRefs(sidebarStore)

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslation = computed(() => {
  return cardItem.value.translations?.find(
    (translation: CardsTranslation) => translation.languages_code === currentLocaleIso.value,
  )
})

const showAllCardsToggle = () => {
  showAllCards.value = !showAllCards.value
  if (!showAllCards.value) {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById('scrollTarget')?.offsetTop,
        behavior: 'smooth',
      })
    }, 150)
  }
}
const hideListLongerThan = computed(() => {
  if (xlAndUp) return 12
  return 8
})
const clickedFraction = ref<Fraction | null>(null)
const goToDestination = async (card: WasteCard) => {
  const fraction = fractions.value?.find(fraction => fraction.id === card.fraction)
  const slug =
    card.button_destination && card.button_destination !== null && card.button_destination !== ''
      ? card.button_destination
      : `/${fraction?.shop_slug}`
  offerStore.setCurrentFractionId(card.fraction)
  router.push({ path: slug })
}
const showMoreInfo = (card: WasteCard) => {
  const fraction = fractions.value?.find(fraction => fraction.id === card.fraction)
  clickedFraction.value = fraction || null
  sidebarStore.openComponentSidebar(ACTIVE_SIDEBAR_TYPE.RULES)
}
onNuxtReady(() => {
  const fractionsSortedIds = fractionsSorted.value?.map(fraction => fraction.id) || []
  sortedCards.value =
    sortedCards.value
      ?.slice()
      .sort(
        (a, b) => fractionsSortedIds.indexOf(a.fraction) - fractionsSortedIds.indexOf(b.fraction),
      ) || []
})
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: none !important;
}
.card-header {
  font-size: 1.25rem;
  white-space: wrap;
  line-height: normal;
  font-weight: 800;
  height: 50px;
}
:deep(.v-card-subtitle) {
  opacity: 1;
}
.mdi-chevron {
  font-size: 2.4rem;
}

.d-none {
  // utility class
  display: none !important;
}

.more-cards :deep(.v-btn__overlay),
.more-cards :deep(.v-ripple__container) {
  opacity: 0;
}

.mobile-btn:deep(span) {
  white-space: wrap !important;
  font-weight: 700;
  font-size: 1.1rem;
}
@media (max-width: 1279px) {
  .mobile-btn {
    font-size: 0.9rem;
    align-content: center;
  }
  :deep(.v-btn__content) {
    white-space: pre-wrap;
  }
}

@media (max-width: 599px) {
  .card-header {
    font-size: 1rem;
    line-height: normal;
  }
  .mobile-btn {
    font-size: 0.75rem;
    padding: 12px 8px;
  }
}
</style>
